import { useQuery } from 'urql'
import { graphql } from 'gql'
import { MagazineArticlesFilterInput } from 'gql/graphql'
import ServiceSlider, { ServiceSliderProps } from '../ServiceSlider'
import { RestArticlePage } from 'data/types/article'
import he from 'he'

interface MagazineCarouselSectionProps extends ServiceSliderProps {
  filters?: MagazineArticlesFilterInput
  articles?: RestArticlePage
  showRegion?: boolean
}

const MagazineCarouselSection = ({
  className,
  filters,
  articles,
  showRegion,
  ...props
}: MagazineCarouselSectionProps) => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query MagazineCarouselSection(
        $filters: MagazineArticlesFilterInput,
      ) {
        magazineArticles(first: 9, filters: $filters) {
          data {
            id
            slug
            thumbnail {
              src
              alt_text
            }
            destination {
              region
              country
            }
            title
            excerpt
            
          }
        }
      }
    `),
    variables: {
      filters: filters,
    },
  })

  if (
    !articles && (fetching || (data?.magazineArticles?.data ?? []).length === 0)
  ) {
    return null
  }

  return (
    <ServiceSlider
      className={className}
      isFetching={fetching}
      button={{
        href: '/magazine',
        text: 'View more',
      }}
      {...props}
      items={(articles?.items ?? data?.magazineArticles?.data)?.map((article) => ({
        superHeading: showRegion ? article.destination?.region : undefined,
        heading: he.decode(article.title),
        subheading: article.excerpt
          ? he.decode(article.excerpt)
          : undefined,
        image: {
          media: article.thumbnail?.src,
          alt_text: article.thumbnail?.alt_text,
        },
        href: `/magazine/${article.slug}`,
      })) ?? []}
    />
  )
}

export default MagazineCarouselSection
